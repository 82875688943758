.new-section {
  position: relative;

  &__wrapper {
    padding-top: ac(226px, 120px);
    padding-bottom: ac(80px, 0px);
    margin: 0 auto;
  }

  &__decors {
    .first-section__top-decor {
      .decor-rect {
        transform: rotate(37.57deg) translate(-28%,-20%);
      }

      @media (max-width: 768px) {
        right: -80px;
        top: -1%;
      }

      @media (max-width: 414px) {
        top: 2%;
      }

      @media (max-width: 375px) {
        right: -117px;
      }
      
      @media (max-width: 360px) {
        right: -150px;
      }
    }

    .first-section__middle-decor {
      right: -94px;
      top: 10.3%;

      @media (max-width: 1024px) {
        right: -130px;
      }
      
      @media (max-width: 768px) {
        right: -87px;
        top: 311px;
      }

      @media (max-width: 414px) {
        right: -87px;
      }
    }

    .first-section__bottom-decor {
      right: 11.5%;
      top: 12.5%;

      @media (max-width: 1024px) {
        right: 0.5%;
        top: 11.1%;
      }
      
      @media (max-width: 768px) {
        right: -5.5%;
      }

      @media (max-width: 414px) {
        right: -5.5%;
      }
    }

    .first-section__bottom-decor-last {
      top: 98%;
      right: 0.5%;

      @media (max-width: 440px) {
        right: -4.5%;
      }
      
      @media (max-width: 360px) {
        right: -9.5%;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding-left: ac(60px, 30px);
    padding-bottom: ac(16px, 8px);
    padding-top: ac(16px, 8px);
    margin-bottom: ac(64px, 32px);


    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: ac(16px, 8px);
      height: 100%;
      background-image: var(--main-gradient);
      background-size: 200% 200%;
      animation: gradient 4s ease infinite;
    }

    &::before {
      filter: blur(11px);
      transform: scale(1.1, 1.02);
    }
  }

  &__title {
    h2 {
      font-family: var(--font-main);
      font-weight: 400;
      font-size: ac(60px, 34px);
      line-height: 1.2;
      margin-bottom: ac(32px, 16px);
    }
  }

  &__subtitle {
    font-size: ac(26px, 20px);
    font-weight: 500;

    line-height: 1.4;

    margin-bottom: ac(24px, 18px);
  }

  &__text {
    margin-bottom: ac(48px, 24px);

    &.content-element {
      p {
        font-weight: 400;
        font-size: ac(20px, 18px);
        line-height: 1.4;

        &:not(:last-child) {
          margin-bottom: ac(16px, 12px);
        }
      }
    }
  }

  &__note {
    display: flex;
    gap: 8px;
    background: #393939;
    padding: ac(16px, 12px) ac(24px, 16px) ac(16px, 12px) ac(20px, 16px);
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      background: #4589FF;
      width: 6px;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
    }

    span {
      &:first-of-type {
        --size: ac(25px, 20px);
        min-width: var(--size);
        width: var(--size);
        height: var(--size);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 2.5px;

        svg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &:not(:first-of-type) {
        font-size: ac(20px, 18px);
        font-weight: 400;
        line-height: 1.4;

        strong {
          font-weight: 600;
        }
      }
    }
  }

  &__block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &:nth-child(-n+2) {
      margin-bottom: ac(48px, 24px);

      @media (max-width: 540px) {
        .new-section__block-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
    }

    &:not(:nth-child(-n + 2)) {
      margin-bottom: ac(44px, 24px);

      .new-section__block-wrapper {
        padding-bottom: 40px;
      }
    }

    &:last-child {
      margin-bottom: ac(40px, 24px);
    }

    &#block-2 {
      .new-section__block-wrapper {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__block-title {
    font-family: var(--font-main);
    font-size: ac(30px, 24px);
    font-weight: 500;
    color: #E8E8E8;
    line-height: 1.2;
  }

  &__block-title-gradient {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(16px, 12px);
    font-size: ac(22px, 20px);
    color: #E8E8E8;

    .gradient {
      --size: 42px;
      min-width: var(--size);
      width: var(--size);
      height: var(--size);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 15;

      span {
        position: relative;
        z-index: 15;
        color: #D9D9D9;
        font-weight: 500;
        font-size: ac(20px, 18px);
      }

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        inset: -1px;
        background: linear-gradient(180deg, #6111D2 0%, #5E17D0 9.4%, #5729CC 22.7%, #4A48C6 38.3%, #3A71BD 55.6%, #24A7B1 74.3%, #0AE8A3 93.9%, #02FE9F 100%);
      }

      &:before {
        content: "";
        position: absolute;
        z-index: 5;
        inset: 1px;
        background: var(--black);
      }
    }

    @media (max-width: 360px) {
      font-size: 17px;
    }
  }

  &__block-wrapper {
    background: #1C1C1C;
    padding: ac(34px, 18px) ac(36px, 20px) ac(36px, 20px) ac(36px, 20px);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 23px;

    .input__wrap,
    .textarea__wrap {
      &:focus-within {
        &::after {
          border-color: #7DCB87;
          border-image-source: none;
        }
      }
    }

    .input__wrap {
      &:after {
        height: ac(48px, 40px);
        border-color: #808080;
      }

      .input__label {
        font-weight: 400;
        font-size: ac(16px, 15px);
        line-height: 1.48;
        text-transform: initial;

        span {
          color: #E73434;
          margin-left: 3px;
        }
      }

      .input {
        font-weight: 500;
        font-size: ac(15px, 14px);
        padding: 13px 14px 13px 16px;
        height: ac(48px, 40px);

        &::placeholder {
          color: #858585;
        }
      }
    }

    .textarea__wrap {
      grid-column: 1 / -1;

      &:after {
        content: none;
      }

      &:focus-within {
        .textarea {
          border-color: #3F6644;
        }
      }

      /*&:before {
        position: absolute;
        content: "";
        background: linear-gradient(180deg, rgba(28, 28, 28, 0) 65%, #1C1C1C 95%);

        z-index: 1;
        bottom: 2px;
        left: 2px;
        right: 2px;
        height: 100%;
        pointer-events: none;
      }*/

      .textarea__label {
        text-transform: initial;
        font-weight: 400;
        font-size: ac(16px, 15px);
        line-height: 1.48;
        letter-spacing: 0.3px;
      }

      .textarea {
        padding: 13px 8px 13px 16px;
        height: 48px;
        min-height: 48px;
        font-weight: 500;
        font-size: 15px;
        resize: none;
        box-sizing: border-box;
        border-left: 1px solid var(--white);
        border-bottom: 1px solid var(--white);
        border-right: 1px solid var(--white);
        transition: height 0.3s ease, max-height 0.3 ease;
        overflow: hidden;

        &::placeholder {
          color: #858585;
        }
      }
    }
  }

  &__block-subtitle {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    grid-column: 1 / -1;

    span {
      width: 24px;
      min-width: 24px;
      height: 24px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      .attention {
        width: 100%;
        height: 100%;

        rect {
          fill: transparent;
        }
      }
    }


    h4 {
      font-weight: 400;
      font-size: ac(20px, 18px);
      line-height: 1.4;
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__button {
    height: 40px;

    &.btn {
      font-weight: 600;
      &:after {
        content: none;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-image: var(--btn-gradient);
        border-radius: inherit;
        transform: translateX(0);
        transition: transform 0.3s, opacity 0.3s;
      }

      &:hover,
      &:focus {
        color: var(--btn-hover-text-color, #fff);
        background: transparent;

        &::before {
          transform: scale(0.5);
          opacity: 0;
        }

        border: 2px solid;
        border-image-source: var(--btn-gradient);
        border-image-slice: 1;
      }
    }
  }
}
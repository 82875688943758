.footer {
  padding: 43px 0;

  @mixin media 769 {
    padding: 118px 0 68px;
  }

  .logo {
    position: relative;

    width: ac(360px, 225px);

    margin-bottom: 24px;
    transition: setTransition(500ms, transform);

    @mixin media 769 {
      margin-bottom: 29px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.footer__main-content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 23px;

  @mixin media 769 {
    align-items: flex-start;
    flex-direction: column;
  }

  @mixin media 551 {
    margin-bottom: 31px;
  }
}

.footer__navbar {
  display: flex;
  flex-wrap: wrap;

  /* margin-right: calc(ac(22px, 12px) * -1); */
  margin-bottom: calc(ac(22px, 12px) * -1);

  @mixin media 769 {
    display: none;
  }
}

.footer__navbar-link {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;

  margin-right: ac(22px, 12px);
  margin-bottom: ac(22px, 12px);
  &:not(:last-child) {
  }

  transition: setTransition(500ms, color);

  &:hover {
    color: #02fe9f;
  }
}

.footer__copyright {
  font-size: 10px;
}

.footer__right {
  position: relative;
  top: 23px;

  display: flex;

  line-height: 1.25;

  @mixin media 551 {
    position: static;

    align-items: unset;
    flex-direction: column;
  }
}

.footer__social-list {
  display: flex;
  align-items: center;
}

.footer__address {
  margin-right: ac(55px, 15px);

  font-size: 13px;

  @mixin media 551 {
    margin-right: 0;
    margin-bottom: 23px;
  }
}

.footer__contacts-wrap {
  width: 215px;
}

.footer__contact-list {
  margin-bottom: 15px;
}

.footer__contact-link {
  position: relative;
  font-size: 13px;
  font-weight: 400;

  transition: setTransition(500ms, color);

  &:hover {
    color: #02fe9f;
  }
}

.footer__social-item {
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.footer__social-link {
  position: relative;
  background: linear-gradient(
    198deg,
    #6111d2 0%,
    #5e17d0 9%,
    #5729cc 23%,
    #4a48c6 38%,
    #3a71bd 56%,
    #24a7b1 74%,
    #0ae8a3 94%,
    #02fe9f 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  font-size: 16px;
  line-height: 0;

  transition: setTransition(transform);

  &:hover {
    -webkit-text-fill-color: var(--primary);
  }
}

.job-page__short-info {
  font-size: ac(21px, 14px);
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.22;
}

.job-descr {
  padding: ac(110px, 40px) 0;
}

.job-descr__content-wrap {
  display: flex;
  justify-content: space-between;

  @mixin media 769 {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.job-descr__content-element {
  flex-grow: 1;

  max-width: 744px;

  margin-right: 40px;
  margin-bottom: ac(70px, 30px);

  @mixin media 769 {
    margin-right: 0;
  }

  @mixin min-media 1650 {
    max-width: 1130px;
  }

  * {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.22;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: 27px;
    }
  }

  ul {
    li {
      position: relative;

      padding-left: 1rem;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.4em;
        display: block;

        width: 8px;
        height: 8px;

        background-image: var(--decor-gradient);
      }
    }
  }
}

.job-descr__main-content-bottom {
  display: flex;
  flex-wrap: wrap-reverse;

  margin-bottom: -16px;
  margin-right: -16px;

  @mixin media 769 {
    margin-bottom: 14px;
  }

  & > * {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.job-descr__share-wrap {
  display: flex;
  align-items: center;
}

.job-descr__share-title {
  margin-right: 13px;

  font-size: 14px;
  text-transform: uppercase;
}

.job-descr__share-list {
  display: flex;
  align-items: center;
}

.job-descr__share-item {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.job-descr__share-link {
  position: relative;
  background: linear-gradient(
    198deg,
    #6111d2 0%,
    #5e17d0 9%,
    #5729cc 23%,
    #4a48c6 38%,
    #3a71bd 56%,
    #24a7b1 74%,
    #0ae8a3 94%,
    #02fe9f 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 16px;
  line-height: 0;

  &:hover {
    -webkit-text-fill-color: var(--primary);
  }
}

.job-descr__teammate {
  width: 231px;
}

.other-jobs-section {
  padding-bottom: ac(122px, 40px);

  .swiper-slide {
    height: auto;
  }
}

.other-jobs-swiper {
  overflow: hidden;

  .swiper-wrapper {
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }
}

.other-jobs-swiper__navigation-wrap {
  display: flex;
  justify-content: center;
}

.other-jobs-swiper__nav-btn {
  &:not(:last-child) {
    margin-right: 9px;
  }

  &.swiper-button-disabled {
    display: none;
  }
}

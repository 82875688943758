.chat-section {
  padding: 160px 0;

  background-color: #1c1c1c;

  @mixin media 769 {
    padding: ac(86.5px, 74px) 0 96.5px;
  }

  .cont {
    max-width: 745px;
  }
}

.chat-section__title {
  position: relative;

  padding: 10px 0 5px ac(39px, 21px);
  margin-bottom: 54px;

  font-size: ac(60px, 30px);
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 1px;

  @mixin media 769 {
    margin-bottom: 65px;

    letter-spacing: 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: ac(8px, 5px);
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  &::before {
    filter: blur(11px);
    transform: scale(1.1, 1.02);
  }
}

.chat-section__sub-title {
  display: block;

  margin-bottom: ac(24px, 7px);

  font-size: ac(45px, 25px);
  font-weight: 400;
}

.chat-section__form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 16px;

  margin-bottom: 20px;

  @mixin media 551 {
    grid-template-columns: 1fr;
    grid-gap: 24.5px;
  }

  .textarea__wrap {
    grid-column: 1 / -1;
  }
}

.chat-section__form-btn {
  margin-left: auto;

  @mixin media 769 {
    width: 146px;

    font-size: 10px;
  }
}

.chat-section__left-decor {
  top: 23%;

  .decor-rect {
    width: vw(418);
    max-width: 418px;
    height: vw(418);
    max-height: 418px;

    transform: translateX(-58%) rotate(325deg);
  }

  @mixin media 1281 {
    .decor-rect {
      transform: translateX(-80%) rotate(325deg);
    }
  }

  @mixin media 1024 {
    .decor-rect {
      width: vw(250);
      max-width: 250px;
      height: vw(250);
      max-height: 250px;
    }
  }

  @mixin media 769 {
    top: unset;
    bottom: 0;

    .decor-rect {
      width: 113px;
      height: 113px;

      transform: translateX(-35%) rotate(124deg);
    }
  }
}

.chat-section__right-decor {
  top: 19%;
  right: 2.5%;

  .decor-rect {
    width: vw(181);
    max-width: 181px;
    height: vw(181);
    max-height: 181px;

    transform: rotate(120deg);
  }

  @mixin media 769 {
    display: none;
  }
}

.chat-section__bottom-decor {
  bottom: 0;
  right: 0;

  .decor-rect {
    width: vw(80);
    max-width: 80px;
    height: vw(80);
    max-height: 80px;

    transform: rotate(60deg);
  }

  @mixin media 769 {
    display: none;
  }
}

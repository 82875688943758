.teammates-list-section {
  padding: 108px 0 124px;

  .cont {
    max-width: 1400px;
  }
}

.teammates-list-section__decor {
  top: 190px;

  .decor-rect {
    width: 80px;
    height: 80px;

    transform: rotate(61deg);
  }

  @mixin media 1025 {
    display: none;
  }
}

.teammates-list-section__title {
  max-width: 934px;

  margin: 0 auto 11px;

  font-size: ac(45px, 20px);
  text-align: center;
  text-transform: uppercase;
  line-height: 1.22;
}

.teammates-list-section__descr {
  max-width: 554px;

  margin: 0 auto ac(143px, 40px);

  font-size: ac(18px, 16px);
  text-align: center;
  line-height: 1.22;
}

.teammates-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 71px 67px;

  @mixin media 365 {
    grid-template-columns: 1fr;
  }
}

.teammate-card {
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100%;

  &--job-page {
    height: fit-content;
    position: sticky;
    top: 120px;

    .teammate-card__photo-wrap {
      margin-bottom: 18px;
    }

    .teammate-card__name {
      font-size: 24px;
    }

    .teammate-card__job-title {
      font-size: 18px;
    }
  }
}

.teammate-card__photo-wrap {
  @mixin aspect-ratio 330, 266;

  margin-bottom: 37px;
}

.teammate-card__info {
  position: relative;

  margin-bottom: 27px;
  padding-left: 29px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 6px;
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  &::before {
    filter: blur(11px);
    transform: scale(1.1, 1.02);
  }
}

.teammate-card__name {
  margin-bottom: 5px;

  font-size: 30px;
  text-transform: uppercase;
}

.teammate-card__job-title {
  margin-bottom: 4px;

  font-size: 21px;
  font-weight: 500;
}

.teammate-card__socials {
  display: flex;
  align-items: center;
}

.teammate-card__socials-item {
  &:not(:last-child) {
    margin-right: 12px;
  }
}

.teammate-card__socials-item-link {
  -webkit-text-fill-color: transparent;
  background: var(--decor-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 16px;
  line-height: 0;
  position: relative;

  &:hover {
    -webkit-text-fill-color: var(--primary);
  }
}

.teammate-card__descr {
  max-height: 263px;

  /* margin-top: auto; */

  font-size: 18px;
  font-weight: 300;
  line-height: 1.22;

  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

body {
  font-family: var(--font-main);
  color: var(--white);
}

h1 {
  font-size: ac(72px, 42px);
  font-weight: 700;

  line-height: 133%;

  margin-bottom: ac(18px, 10px);
}

h2 {
  font-size: ac(62px, 36px);
  font-weight: 600;

  line-height: 130%;

  margin-bottom: 14px;
}

h3 {
  font-size: ac(46px, 26px);
  font-weight: 600;
}

h4 {
  font-size: ac(32px, 20px);
  font-weight: 500;

  line-height: 130%;

  margin-bottom: ac(20px, 16px);
}

h5 {
  font-size: ac(26px, 20px);
  font-weight: 500;

  line-height: 1.5;

  margin-bottom: ac(18px, 10px);
}

h6 {
  font-size: ac(22px, 18px);
  font-weight: 500;

  line-height: 2;
}

p {
  font-size: ac(16px, 14px);
  font-weight: 500;

  line-height: 200%;
}

a {
  font-size: ac(16px, 14px);
  font-weight: 500;
  color: var(--white);
  display: inline-block;

  line-height: 1.5;
}

.content-element,
.article__content,
.job-descr__content-element {
  * {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.22;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: 27px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin-bottom: 19px;

    &:not(:first-child) {
      margin-top: ac(50px, 30px);
    }
  }

  h1 {
    font-size: ac(70px, 30px);
    font-weight: 700;
  }

  h2 {
    font-size: ac(60px, 28px);
    font-weight: 600;
  }

  h3 {
    font-size: ac(50px, 26px);
  }

  h4 {
    font-size: ac(40px, 24px);
  }

  h5 {
    font-size: ac(30px, 20px);
  }

  h6 {
    font-size: ac(20px, 16px);
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul {
    li {
      position: relative;

      padding-left: 1rem;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.4em;
        display: block;

        width: 8px;
        height: 8px;

        background-image: var(--decor-gradient);
      }
    }
  }

  strong,
  b {
    font-weight: 600;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: var(--primary);
    }
  }
}

/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  transition: background ease-in-out 0.25s, height ease-in-out 0.25s;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: ac(145px, 75px);
  padding: ac(25px, 15px) 0;
  display: flex;
  align-items: center;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &.scrolled {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    height: ac(100px, 60px);

    .logo {
      width: ac(200px, 150px);
    }
  }

  .logo {
    z-index: 1;

    width: ac(269px, 171px);
    height: 100%;
    flex-shrink: 0;

    transition: setTransition(500ms, width, transform);

    &::before {
      display: none;
    }

    &:hover {
      transform: scale(1.05);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar-nav__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: ac(32px, 14px);

      @media (max-width: 540px) {
        .btn {
          display: none;
        }
      }

      .btn {
        height: 40px;
        padding-left: ac(24px, 12px);
        padding-right: ac(24px, 12px);
        width: 100%;

        span {
          font-weight: 400;
          font-size: 14px;
          color: #D9D9D9;
        }
      }
    }
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    position: relative;

    display: flex;
    flex-grow: 1;

    .cont {
      display: flex;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    margin-left: -10px;
  }

  .menu-item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @media (--mobile-menu-end-point) {
        padding: 5px 0;
      }

      &:hover {
        @media (--mobile-menu-end-point) {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;

    padding-left: 10px;

    font-size: ac(45px, 20px);
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;

    transition: setTransition(500ms, color, transform);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;

      background: var(--main-gradient);

      opacity: 0;

      transition: setTransition(500ms, opacity);
    }

    &:hover {
      color: var(--primary);

      transform: translateX(10px);

      &::before {
        opacity: 1;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 50%;
      height: 100vh;
      min-width: 320px;
      padding-top: 100px;
      background: var(--bg-second);
      padding-right: 5px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        align-items: flex-start;
        align-self: center;

        &::-webkit-scrollbar {
          width: 3px;
          height: 2px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.5);
          border-radius: 3px;
          cursor: pointer;
        }
      }

      .menu-link {
        padding: 10px 0;
        font-size: ac(30px, 22px);
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}

/* end of Common desktop header Mobile HEADER */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.header.header-mobile {
  .burger {
    display: flex;
  }

  .navbar-nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    background: #000000;
    padding-right: 5px;
    padding-bottom: 130px !important;
    opacity: 0;
    visibility: hidden;

    transition: setTransition(600ms, transform, opacity, visibility);

    &.nav-slide-left {
      right: auto;
      left: 0;
      transform: translateX(-100%);

      &.active {
        transform: translateX(0);
      }
    }

    &.nav-slide-right {
      right: 0;
      left: auto;
      transform: translateX(25%);

      &.active {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;

        .navbar__top-decor {
          transition: transform ease 1750ms, opacity ease 2250ms;

          transform: translateX(0px);
          opacity: 1;
        }
        .navbar__middle-decor {
          transition: transform ease 1750ms 500ms, opacity ease 2250ms 500ms;

          transform: translateX(0px);
          opacity: 1;
        }
        .navbar__bottom-decor {
          transition: transform ease 1750ms 1000ms, opacity ease 2250ms 1000ms;

          transform: translateX(0px);
          opacity: 1;
        }
      }
    }

    &.nav-slide-down {
      right: auto;
      left: 0;
      transform: translateY(-100%);

      &.active {
        transform: translateY(0);
      }
    }

    &.nav-slide-up {
      right: auto;
      left: 0;
      transform: translateY(100%);

      &.active {
        transform: translateY(0);
      }
    }

    .menu {
      overflow-y: auto;

      flex-direction: column;

      align-items: flex-start;
      align-self: center;
      justify-content: center;

      width: 100%;
      height: 100%;
      max-height: 100%;

      &::-webkit-scrollbar {
        width: 3px;
        height: 2px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 3px;
        cursor: pointer;
      }

      .btn {
        width: 100%;
        margin-top: auto;

        @media (min-width: 550px) {
          display: none;
        }
      }


      @media (max-width: 540px) {
        padding-top: 20vh;
      }
    }
  }

  .dropdown-menu {
    position: relative;
    top: 0;
  }
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: ac(48px, 36px, 375, 1025);
  height: 35px;
  display: flex;

  span {
    width: 100%;
    height: 2px;
    background: var(--white);
    transition: all 0.1s;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--white);

      transition: all 0.1s;
    }

    &::before {
      top: -14px;
    }

    &::after {
      bottom: -14px;
    }
  }

  @mixin transition-all;

  @mixin min-media 1025 {
    &:hover {
      transform: scale(1.1);
    }
  }

  &.active {
    height: 40px;

    span {
      background: transparent;

      &::before {
        top: 0;
        transform: rotate(-45deg);
      }

      &::after {
        top: 0;
        transform: rotate(45deg);
      }
    }

    &:hover {
      height: 40px;
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  @mixin transition-all;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

.navbar__top-decor {
  top: 0;
  right: 0;

  transform: translateX(75%) rotate(30deg);
  opacity: 0;

  transition: transform ease 500ms, opacity ease 500ms;

  .decor-rect {
    width: vw(417);
    max-width: 417px;
    min-width: 250px;
    height: vw(417);
    max-height: 417px;
    min-height: 250px;

    transform: rotate(38deg) translate(-28%, 4%);

    @mixin media 1850 {
      transform: rotate(38deg) translate(-15%, 4%);
    }

    @mixin media 551 {
      transform: rotate(38deg) translate(0%, -50%);
    }
  }
}

.navbar__middle-decor {
  right: 32%;
  top: 36%;

  transform: translateX(300%) rotate(-20deg);
  opacity: 0;

  transition: transform ease 500ms, opacity ease 500ms;

  .decor-rect {
    width: vw(80);
    max-width: 80px;
    min-width: 50px;
    height: vw(80);
    max-height: 80px;
    min-height: 50px;

    transform: rotate(61deg);
  }

  @mixin media 551 {
    right: 10%;
  }
}

.navbar__bottom-decor {
  right: 4%;
  bottom: 28%;

  transform: translateX(100%) rotate(-20deg);
  opacity: 0;

  transition: transform ease 500ms, opacity ease 500ms;

  .decor-rect {
    width: vw(180);
    max-width: 180px;
    min-width: 100px;
    height: vw(180);
    max-height: 180px;
    min-height: 100px;

    transform: rotate(113deg);
  }
}

/* end of HEADER COMPONENTS */

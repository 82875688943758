.portfolio-section {
  height: 100vh;
  min-height: 740px;

  padding-top: 100px;
  padding-bottom: 50px;

  @mixin media 901 {
    height: auto;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    background-color: rgba(0, 0, 0, 0.5);
  }

  .cont {
    position: relative;
    z-index: 2;

    height: 100%;
  }
}

.portfolio__top-decor {
  top: 0;
  right: 0;

  .decor-rect {
    transform: rotate(37.57deg) translate(5%, -46%);

    width: vw(417);
    max-width: 417px;
    min-width: 350px;
    height: vw(417);
    max-height: 417px;
    min-height: 350px;
  }

  @mixin media 1921 {
    .decor-rect {
      transform: rotate(37.57deg) translate(-25%, -53%);
    }
  }

  @mixin media 901 {
    display: none;
  }
}

.portfolio-section__left-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;

  width: 50%;
  height: 100%;
  object-fit: cover;

  opacity: 0.5;

  @mixin media 901 {
    width: 100%;
    opacity: 0.35;
  }
}

.portfolio {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  @mixin media 901 {
    flex-direction: column;
  }
}

.portfolio__left-block,
.portfolio__right-block {
  width: 50%;

  @mixin media 901 {
    width: 100%;
  }
}
.portfolio__left-block {
  overflow: hidden;

  margin-left: -30px;
  padding-left: 30px;
  padding-right: 28px;
  padding-top: ac(105px, 0px);

  @mixin media 901 {
    padding-right: 0;
  }
}

.portfolio__right-block {
  padding-left: ac(84px, 30px);
  padding-top: ac(164px, 0px);

  @mixin media 901 {
    padding-left: 0;
  }
}

.portfolio__title {
  margin-bottom: ac(75px, 25px);
  padding: ac(20px, 5px) 0 ac(20px, 5px) ac(39px, 20px);

  font-size: ac(60px, 40px);
  text-transform: uppercase;

  @mixin media 360 {
    font-size: 32px;
  }
}

.portfolio__clients-slider {
  --slide-height: 86px;
  --slide-gap: 37px;
  /* width: 100%; */

  .swiper-wrapper {
    max-height: calc(var(--slide-height) * 3 + var(--slide-gap) * 2);
    /* justify-content: center; */
    /* align-items: center; */

    @mixin media 901 {
      justify-content: unset;

      margin-bottom: 35px;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    /* width: 364px; */

    @mixin media 901 {
      width: fit-content;
    }

    @mixin media 551 {
      width: 100%;
    }
  }
}

.portfolio__client-logo {
  max-height: 100%;

  margin: 0 auto;

  opacity: 0.4;
  filter: brightness(0) invert(1);

  cursor: pointer;

  transition: setTransition(opacity);

  /* @mixin media 901 {
    max-height: unset;
  } */

  &.active {
    opacity: 1;
  }
}

.portfolio__descr-slider {
  backdrop-filter: blur(10px);

  margin-right: -20px;
  padding: 20px;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide.swiper-slide-active {
    .portfolio__descr {
      transform: scale(1);
    }
  }
}

.portfolio__descr {
  transform: scale(0.75);

  transition: setTransition(500ms, transform);
}

.portfolio__descr-title {
  margin-bottom: ac(29px, 10px);

  font-size: 21px;
  font-weight: 500;
  line-height: 1.23;
}

.portfolio__descr-main-text {
  max-height: 500px;

  margin-bottom: 29px;

  & > * {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.22;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

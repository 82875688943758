.input__wrap,
.textarea__wrap {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;

  width: 100%;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;

    width: 100%;
    height: 27px;

    border-left: 1px solid var(--white);
    border-bottom: 1px solid var(--white);
    border-right: 1px solid var(--white);
  }

  &:focus-within {
    &::after {
      border-image-slice: 1;
      border-image-source: var(--btn-gradient);
    }
  }
}

.input__label,
.textarea__label {
  margin-bottom: 8px;

  font-size: 14px;
  text-transform: uppercase;

  cursor: pointer;
}

.input,
.textarea {
  display: block;

  padding: 10px 5px 15px;

  @mixin transparent-bg-input;

  background-color: transparent;
}

.input {
  width: 100%;
  height: 27px;
}

.textarea {
  width: 100%;
  min-height: 112px;
  resize: none;

  @mixin media 769 {
    min-height: 127px;
  }
}

.select2 {
  width: 100% !important;

  margin-top: -5px;

  .select2-selection {
    background-color: transparent !important;
    border: none !important;

    &.select2-selection--multiple {
      /* padding-bottom: 0 !important; */
      /* bottom: 2px; */
    }

    .select2-search {
      display: none;
    }
  }

  .select2-selection__choice {
    backdrop-filter: blur(10px);
    background-color: transparent !important;
    background-image: var(--main-gradient) !important;
    border: none !important;
    border-radius: 0 !important;

    .select2-selection__choice__remove {
      border: none !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .select2-container--default.select2-container--focus {
    border: none !important;
  }
}

.select2-dropdown {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(5px);
  border: 1px solid var(--white);
  border-image-slice: 1;
  border-image-source: var(--btn-gradient);
  border-radius: 0;

  .select2-results__options {
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 1);
      border-radius: 50px;
    }
  }

  .select2-results__option--selected {
    background-color: rgba(2, 254, 159, 0.25);
    /* color: var(--black); */
  }

  .select2-results__option--highlighted.select2-results__option--selectable {
    background-image: linear-gradient(
      65deg,
      #6111d2,
      #5e17d0 9%,
      #5729cc 23%,
      #4a48c6 38%,
      #3a71bd 56%,
      #24a7b1 74%,
      #0ae8a3 94%,
      #02fe9f
    );
  }
}

.meta-input,
.meta-select,
.meta-upload-file {
  label,
  .label {
    font-weight: 400;
    font-size: ac(16px, 15px);
    line-height: 1.48;
    text-transform: initial;
    margin-bottom: 8px;

    span {
      margin-left: 4px;
      display: inline-block;
      color: #E73434;
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: visible;
  width: 100%;

  select {
    opacity: 0;
    height: ac(56px, 50px);
  }

  &__container {
    border-radius: 30px;
    width: 100%;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;
    position: relative;

    &:not(.is-open) {
      overflow: hidden;
    }

    &__inner {
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
      border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 13px 14px 13px 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      height: ac(48px, 40px);
      background: transparent;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      color: #fff;
      outline: none;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #808080;



      /*&:hover {
        border-color: var(--cl-orange);
      }*/
      /*&.error {
        border-color: var(--cl-red);
        box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
      }*/
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-top: 8px;
        }
      }

      &.is-flipped {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 8px;
        }
      }
    }

    &__item {
      @mixin max-line-length-one;
    }

    &__placeholder {
      color: var(--cl-white);
      opacity: 1;
      background: transparent;
    }

    &__list {
      .choices__item {
        padding-right: ac(36px, 30px);
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-family: var(--font-main);
        color: var(--cl-white);
        transition: all 0.3s ease;
        text-decoration: underline;
        text-decoration-color: transparent;

        &.is-selected {
          color: var(--cl-medium-blue);
          font-weight: 700;
        }
      }

      &--single {
        background-color: transparent !important;

        .choices__item {
          color: var(--cl-white);
        }
      }

      &--multiple {
        color: var(--cl-white);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }

      &--dropdown {
        .choices__item {
          color: var(--cl-navy);
          text-decoration: none !important;

          &:hover {
            &:not(.is-selected) {
              color: var(--cl-white) !important;
              text-decoration: none !important;
              background: var(--cl-ice) !important;
            }
          }
        }
      }
    }

    .choices__list--dropdown {
      background: #808080;

      .choices__list {
        .choices__item {
          padding: 8px 16px 8px 16px;

          &:not(:last-child) {
            border-bottom: 1px solid var(--white);
          }
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;

      &:after {
        min-width: 24px;
        min-height: 24px;
        border: none;
        content: '';
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        position: absolute;
        right: 14px; /* Исправлено */
        top: 50%;
        transform: translateY(-50%); /* Центрируем вертикально */
        transition: all 0.15s ease-in-out;
        z-index: 2;

        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%226%22%20viewBox%3D%220%200%2010%206%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M5%205.52301L0%200.52301H10L5%205.52301Z%22%20fill%3D%22white%22/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px 5px;
      }
    }

    &.is-open {
      &:after {
        transform: translateY(-50%) scaleY(-1);
      }

      &__list--dropdown,
      .choices__list[aria-expanded] {
        opacity: 1;
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-navy) !important;
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      z-index: 20;
      border-radius: 0;
      position: absolute;
      top: 48px;
      left: 0;
      right: 0;
      opacity: 0;


      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #808080;

    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 1px solid var(--cl-white);
      border-radius: 0;
      background: var(--cl-white);
      color: var(--cl-white);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-second);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }
}

.default-custom-select-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .label {
    font-family: var(--font-md-bt);
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    color: var(--cl-primary);
    padding-bottom: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-red);
    }
  }

  .default-custom-select {
    width: 100%;
  }
}

.default-custom-select {
  display: inline-flex;
  padding: 8px ac(19px, 18px) !important;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ac(60px, 50px);
  min-height: ac(60px, 50px);
  background: var(--cl-white);
  border: 1px solid var(--cl-white);
  border-radius: 30px;
  transition: all 0.25s ease;
  position: relative;
  cursor: pointer;
  z-index: 20;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);

  &__placeholder {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: var(--font-second);

    color: var(--cl-primary);
    @mixin max-line-length-one;
  }

  &__list {
    position: absolute;
    width: 100%;
    background: #fff;
    border: 1px solid var(--cl-primary);
    margin-top: -1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    bottom: -8px;
    left: -1px;
    right: -1px;
    transform: translateY(100%);
    z-index: 100;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 8px;
    cursor: default;
    padding: 10px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }

  &.active {
    .default-custom-select__list {
      display: flex;
    }
  }

  &:before {
    border: none;
    content: '\e900';
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    position: absolute;
    right: ac(20px, 19px);
    top: 50%;
    transition: all 0.15s ease-in-out;
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-top: 0;
    z-index: 2;
    font-size: 9px;
    color: var(--cl-primary);
    transform: translateY(-50%);

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &.active {
    &:before {
      transform: translateY(-50%) scaleY(-1);
    }
  }
}

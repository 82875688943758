.five-reasons-section {
  z-index: 1;

  margin-bottom: -50px;

  @mixin media 951 {
    height: auto;

    margin-bottom: 0;
  }

  .cont {
    width: 100%;
    max-width: 1920px;

    display: flex;
  }
}

.fr-title-swiper {
  overflow: hidden;

  width: 36%;

  @mixin media 951 {
    display: none;
  }
}

.fr-title-swiper__slide-wrap {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  height: 100%;

  padding-left: 138px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.35);
  }
}

.fr-title-swiper__img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fr-title-swiper__num {
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  margin-top: 10px;
  margin-bottom: 17px;

  background-image: var(--decor-gradient);

  font-size: 45px;
  font-weight: 600;
}

.fr-title-swiper__title {
  z-index: 1;

  font-size: 45px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;

  @mixin media 1281 {
    font-size: 30px;
  }
}

.five-reasons__right-wrap {
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 64%;

  @mixin media 951 {
    width: 100%;
  }
}

.five-reasons__descr-wrap {
  padding: 95px 138px 99px 109px;

  @mixin media 951 {
    padding: 22px 36px;
  }
}

.five-reasons__title {
  position: relative;

  margin-bottom: 20px;
  padding: 11px 0 11px 16px;
  padding-left: 16px;

  font-size: ac(45px, 30px);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.75px;

  @mixin media 769 {
    margin-bottom: 59px;
    padding: 10px 0 0 20px;
    line-height: 1.2;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 6px;
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }
}

.fr-descr-swiper {
  overflow: hidden;
  width: 100%;

  padding-left: 20px;
  padding-bottom: 20px;
  margin-left: -20px;
  margin-bottom: -20px;

  .swiper-wrapper {
    margin-bottom: 30px;

    @mixin media 769 {
      margin-bottom: 43px;
    }
  }
}

.fr-descr-swiper__slide-wrap {
  max-height: 250px;

  @mixin media 951 {
    max-height: 390px;
  }

  h3 {
    margin-bottom: 15px;

    font-size: ac(21px, 18px);
    font-weight: 500;
  }

  p {
    padding: 0;

    font-size: ac(18px, 16px);
    font-weight: 300;
    line-height: 1.25;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    @mixin media 759 {
      line-height: 1.43;
    }
  }

  a {
    color: var(--primary);

    &:hover {
      text-decoration: underline;
    }
  }
}

.fr-descr-swiper__slide-num-title {
  display: none;
  align-items: center;

  margin-bottom: 32px;

  @mixin media 951 {
    display: flex;
  }
}

.fr-descr-swiper__slide-num {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 45px;
  height: 47px;

  margin-right: 23px;

  background-image: var(--decor-gradient);

  font-size: 25px;
  font-weight: 700;

  @mixin media 361 {
    margin-right: 7px;
  }
}

.fr-descr-swiper__slide-title {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;

  @mixin media 361 {
    /* margin-right: 20px; */
    font-size: 20px;
  }
}

.fr-descr-swiper__nav-wrap {
  display: flex;
}

.fr-descr-swiper__nav-btn {
  width: 35px;
  height: 35px;

  font-size: 9.5px;

  &.left {
    margin-right: 9px;
  }
}

.fr-other-swiper {
  width: 269px;
  height: 238px;

  margin-top: auto;

  transform: translateX(-100%);

  @mixin media 951 {
    display: none;
  }
}

.fr-other-swiper__slide-wrap {
  height: 100%;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgb(28, 28, 28);
  }

  &::before {
    opacity: 0.65;
  }

  &::after {
    mix-blend-mode: color;
  }
}

.fr-other-swiper__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

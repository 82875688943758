.first-section-title-mediahub {
  margin: 0 auto 48px;
  width: fit-content;
}

.media-hub__search-form-wrap {
  width: 91%;

  margin: 0 auto;

  @mixin media 1025 {
    width: 100%;
  }
}

.media-hub__search-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-right: -16px;
  margin-bottom: -16px;

  & > * {
    flex: 1 1 200px;

    margin-right: 16px;
    margin-bottom: 16px;
    /* &:not(:last-child) {
    } */

    @mixin media 551 {
      flex: 1 1 100%;
    }
  }

  .input__label {
    /* margin-bottom: 0; */
  }
}

.media-hub__input {
  margin-top: auto;
}

.media-hub__search-form-btn {
  max-width: 174px;

  align-self: flex-end;

  @mixin media 1025 {
    max-width: unset;
    flex: 0 0 416px;

    /* margin-left: auto; */
  }

  @mixin media 551 {
    flex: 1 1 100%;

    margin: 0 16px 16px 0;
  }
}

.article-list-section {
  padding: 105px 0 109px;

  .cont {
    max-width: 1400px;
  }
}

.article-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 81px 51px;

  &:not(:last-child) {
    margin-bottom: 69px;
  }

  @mixin media 1281 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 769 {
    grid-template-columns: 1fr;
  }

  .title_result {
    font-size: 30px;
    text-align: center;
  }
}

.article-card {
  display: flex;
  flex-direction: column;

  height: 100%;

  &:hover {
    .article-card__title {
      color: var(--primary);
    }
  }
}

.article-card__img-wrap {
  @mixin aspect-ratio 341, 96;

  margin-bottom: 25px;
}

.article-card__title-wrap {
  position: relative;

  margin-bottom: 21px;
  padding: 10px 0 10px 29px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 6px;
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  &::before {
    filter: blur(11px);
    transform: scale(1.1, 1.02);
  }
}

.article-card__title {
  @mixin max-line-length 4;

  width: 94%;

  margin-bottom: 8px;

  font-size: 30px;
  line-height: 1.23;
  text-transform: uppercase;

  transition: setTransition(color);
}

.article-card__info-wrap {
  display: flex;

  color: var(--primary);

  font-size: 16px;
  font-weight: 500;
}

.article-card__date {
  margin-right: 23px;
}

.article-card__descr {
  max-height: 66px;

  margin-top: auto;
  margin-bottom: 21px;

  font-size: 18px;
  font-weight: 300;
  line-height: 1.22;
}

.article-list__item {
  /* max-width: 675px; */
}

.article-list__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.article-list__pagination-btn {
  width: 40px;
  height: 40px;

  font-size: 12px;

  &:not(:last-child) {
    margin-right: 9px;
  }
}

.fp-watermark {
  display: none;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--black);
  color: var(--white);

  &.disabled {
    overflow-y: scroll;
    position: fixed;

    width: 100%;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.grecaptcha-badge {
  z-index: 2;
}

.main {
  flex-grow: 1;
}

%default-transition {
  transition: ease-in-out 0.25s;
}

.cont {
  margin: 0 auto;
  max-width: 1640px;
  width: 80.5%;
}

section {
  position: relative;
}

.sticky {
  position: sticky;
  top: 0;

  &:before,
  &:after {
    content: "";
    display: table;
  }
}

.first-section {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  min-height: 500px;

  padding-top: 84px;

  background-image: url("../images/main-img.jpg");
  background-size: cover;
  background-position: center;

  .cont {
    z-index: 2;
  }

  @mixin media 769 {
    padding-top: 36px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: block;

    background-color: rgba(0, 0, 0, 0.45);
  }
}

.first-section__video,
#suspend-video-poster {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

#suspend-video-poster {
  display: none;
}

.first-section__title {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;

  /* stylelint-disable-next-line length-zero-no-unit */
  padding: ac(70px, 0px) 0 ac(70px, 0px) 39px;

  font-size: ac(60px, 40px);
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: 1px;

  @mixin media 769 {
    line-height: 1.27;
    letter-spacing: 0;
  }

  @mixin media 361 {
    font-size: 32px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: ac(16px, 8px);
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  &::before {
    filter: blur(11px);
    transform: scale(1.1, 1.02);
  }
}

.decor-rect__wrap {
  position: absolute;
  z-index: 1;

  pointer-events: none;
}

.decor-rect {
  --decor-border-width: ac(8px, 3px);

  position: relative;

  width: 100px;
  height: 100px;
  border: var(--decor-border-width) solid;
  border-image-source: var(--decor-gradient);
  border-image-slice: 1;
  border-width: var(--decor-border-width);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: calc(100% + var(--decor-border-width));
    height: calc(100% + var(--decor-border-width));

    border: var(--decor-border-width) solid;
    border-image-source: var(--decor-gradient);
    border-image-slice: 1;
    border-width: var(--decor-border-width);

    filter: blur(11px);
  }
}

.first-section__top-decor {
  top: 0;
  right: 0;

  .decor-rect {
    transform: rotate(37.57deg) translate(5%, -46%);

    width: vw(417);
    max-width: 417px;
    min-width: 350px;
    height: vw(417);
    max-height: 417px;
    min-height: 350px;
  }

  @mixin media 1921 {
    .decor-rect {
      transform: rotate(37.57deg) translate(-22%, -25%);
    }
  }

  @mixin media 651 {
    top: 30%;
    right: -80px;

    .decor-rect {
      transform: rotate(38deg) translate(-16%, -53%);

      width: 155px;
      min-width: unset;
      height: 155px;
      min-height: unset;
    }
  }
}

.first-section__middle-decor {
  top: 58.5%;
  right: -15px;

  .decor-rect {
    transform: rotate(121deg);

    width: 180px;
    height: 180px;
  }

  @mixin media 1351 {
    right: -50px;
  }

  @mixin media 1025 {
    right: -15px;
  }

  @mixin media 651 {
    top: 68%;
    right: -35px;

    .decor-rect {
      width: vw(180);
      max-width: 180px;
      min-width: 100px;
      height: vw(180);
      max-height: 180px;
      min-height: 100px;
    }
  }
}

.first-section__bottom-decor {
  right: 16.5%;
  top: 69.5%;

  .decor-rect {
    transform: rotate(61deg);

    width: 80px;
    height: 80px;
  }

  @mixin media 1025 {
    right: 27%;
  }

  @mixin media 651 {
    top: 72%;

    /* .decor-rect {
      width: vw(80);
      max-width: 80px;
      min-width: 44px;
      height: vw(80);
      max-height: 80px;
      min-height: 44px;
    } */
  }

  @mixin media 551 {
    .decor-rect {
      width: 44px;
      height: 44px;
    }
  }
}

.first-section-inner {
  min-height: ac(544px, 400px);

  padding-top: ac(267px, 200px);
  padding-bottom: 30px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    object-fit: cover;

    background-color: rgba(0, 0, 0, 0.5);
  }
}

.first-section-inner__bg-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;

  width: 100%;
  height: 100%;
  object-fit: cover;

  opacity: 0.5;
}

.first-section-inner__title {
  position: relative;
  z-index: 2;

  padding: ac(10px, 0px) 0 ac(10px, 0px) 39px;

  font-weight: 600;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: ac(16px, 8px);
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  &::before {
    filter: blur(11px);
    transform: scale(1.1, 1.02);
  }

  @mixin media 360 {
    padding: 10px 0 10px 20px;
  }
}

.first-section-inner__main-title {
  margin-bottom: 0;

  font-size: ac(60px, 30px);
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @mixin media 360 {
    font-size: 25px;
  }
}

.first-section-inner__subtitle {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.33;
  text-transform: uppercase;
}

.simplebar-offset {
  padding-right: 15px;
}

.simplebar-track.simplebar-vertical {
  width: 5px;
}

.simplebar-scrollbar {
  background-image: var(--main-gradient);

  opacity: 0;

  transition: setTransition(opacity);

  &.simplebar-visible {
    opacity: 1;
  }

  &::before {
    background: transparent;
  }
}

.single-page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;

  .logo-404 {
    margin-bottom: 40px;
  }

  .title-404 {
    margin-bottom: 0;

    text-align: center;
  }

  .text-404 {
    margin-bottom: 30px;

    text-align: center;
  }
}

.page404__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;

  backdrop-filter: blur(5px);
}

.page404__top-decor {
  top: 0;
  right: 0;
  z-index: -1;

  .decor-rect {
    width: 180px;
    height: 180px;

    transform: rotate(132deg) translate(50%, 10%);
  }

  @mixin media 651 {
    top: -10%;
  }
}

.page404__middle-decor {
  top: 50%;
  left: 0;
  z-index: -1;

  .decor-rect {
    width: 100px;
    height: 100px;

    transform: rotate(44deg) translate(50%, 10%);
  }

  @mixin media 769 {
    top: 60%;
  }
}

.page404__bottom-decor {
  bottom: 0;
  right: 0;
  z-index: -1;

  .decor-rect {
    width: 80px;
    height: 80px;

    transform: translate(-50%, -100%) rotate(165deg);
  }
}

.terms-section {
  padding-top: ac(150px, 85px);
}

.bold-logo {
  display: block;

  width: 150px;

  margin: 20px auto 0;

  transition: transform ease 250ms;

  &:hover {
    transform: scale(1.05);
  }
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }
}

.popup-form.apply-kerv {
  .pc-label {
    font-family: var(--font-main);
    text-transform: uppercase;

    &.check-title {
      text-transform: none;
    }
  }
}

.snackbar-container {
  z-index: 99999 !important;
}

/* .grecaptcha-badge {
  bottom: 75px !important;
} */


.ui-widget-header {
  background: #1c1c1c !important;
  border-color: #858585 !important;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #fed22f !important;
  background: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #363636;
}

.what-included-section {
  padding: ac(200px, 166px) 0 ac(172px, 94px);

  background-image: url("../images/what-included-bg.png");
  background-size: 112%;
  background-position: center;

  @mixin media 1281 {
    background-size: cover;
  }

  @mixin media 551 {
    background-image: url("../images/what-included-bg-mob.png");
    background-position: 50% 100px;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;

      background-image: linear-gradient(
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 1)
      );
    }
  }

  .cont {
    position: relative;
    z-index: 1;
  }
}

.what-included-section__title {
  margin-bottom: 13px;

  font-size: ac(45px, 30px);
  text-align: center;
  text-transform: uppercase;

  @mixin media 769 {
    text-align: left;
  }
}

.what-included-section__descr {
  max-width: 649px;

  margin: 0 auto 15px;

  font-size: ac(18px, 16px);
  font-weight: 300;
  text-align: center;
  line-height: 1.25;

  @mixin media 769 {
    margin-bottom: 24px;

    line-height: 1.45;
    letter-spacing: 0.5px;
    text-align: left;
  }
}

.what-included-section__slider-title {
  margin-bottom: ac(49px, 46px);

  font-size: ac(24px, 18px);
  font-weight: 500;
  text-align: center;

  @mixin media 769 {
    text-align: left;
  }
}

.what-included-slider__wrap {
  position: relative;
  max-width: 849px;

  margin: 0 auto;
}

.what-included-slider {
  overflow: hidden;

  max-width: 643px;

  margin: 0 auto;
}

.what-included-slider__card {
  display: flex;
  align-items: flex-start;

  @mixin media 769 {
    flex-direction: column;
  }
}

.what-included-slider__card-title {
  position: relative;

  width: 200px;

  margin-right: ac(81px, 40px);
  padding-left: ac(33px, 22px);

  font-size: ac(30px, 25px);
  text-transform: uppercase;
  line-height: 1.25;

  @mixin media 769 {
    width: auto;
    max-width: 230px;

    margin-bottom: 27px;
    margin-right: 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: ac(8px, 5px);
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  &::before {
    filter: blur(11px);
    transform: scale(1.1, 1.02);
  }
}

.what-included-slider__card-text {
  width: 100%;
  max-height: 218px;

  font-size: ac(18px, 16px);
  font-weight: 300;
  line-height: 1.22;

  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  @mixin media 769 {
    letter-spacing: 0.25px;
    line-height: 1.42;
  }
}

.what-included-slider__nav-wrap {
  display: flex;

  @mixin media 1281 {
    margin-top: 34px;
    justify-content: center;
  }

  @mixin media 551 {
    justify-content: flex-start;
  }
}

.what-included-slider__nav-btn {
  position: absolute;
  top: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 35px;

  color: var(--white);

  font-size: 9.5px;

  @mixin media 1281 {
    position: relative;
    top: 0;
  }

  &.left {
    left: 0;

    @mixin media 1281 {
      margin-right: 9px;
    }
  }

  &.right {
    right: 0;
  }
}

@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap";

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?p7q2pv");
  src: url("fonts/icomoon.eot?p7q2pv#iefix") format("embedded-opentype"),
    url("fonts/icomoon.woff2?p7q2pv") format("woff2"),
    url("fonts/icomoon.ttf?p7q2pv") format("truetype"),
    url("fonts/icomoon.woff?p7q2pv") format("woff"),
    url("fonts/icomoon.svg?p7q2pv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e908";
}
.icon-checkmark:before {
  content: "\e907";
}
.icon-mail:before {
  content: "\e905";
}
.icon-phone:before {
  content: "\e906";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-fb:before {
  content: "\e902";
}
.icon-ig:before {
  content: "\e903";
}
.icon-in:before {
  content: "\e904";
}

:root {
  --black: #000000;
  --bg-second: #343434;
  --main: #3cc5fb;
  --white: #ffffff;
  --primary: #02fe9f;
  --secondary: #088ded;

  --main-gradient: linear-gradient(
    141deg,
    #6111d2 0%,
    #5e17d0 9%,
    #5729cc 23%,
    #4a48c6 38%,
    #3a71bd 56%,
    #24a7b1 74%,
    #0ae8a3 94%,
    #02fe9f 100%
  );

  --decor-gradient: linear-gradient(
    180deg,
    #6111d2 0%,
    #5e17d0 9%,
    #5729cc 23%,
    #4a48c6 38%,
    #3a71bd 56%,
    #24a7b1 74%,
    #0ae8a3 94%,
    #02fe9f 100%
  );

  --btn-gradient: linear-gradient(
    259deg,
    #6111d2 0%,
    #5e17d0 9%,
    #5729cc 23%,
    #4a48c6 38%,
    #3a71bd 56%,
    #24a7b1 74%,
    #0ae8a3 94%,
    #02fe9f 100%
  );

  --font-main: "Montserrat", sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1200px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1201px);

.first-section-article-page {
  padding-top: 202px;
}

.article-page-title {
  max-width: 803px;

  margin-bottom: 0;

  line-height: 1.18;

  @mixin min-media 1600 {
    max-width: 80%;
  }
}

.article-page__info-wrap {
  display: flex;

  margin-bottom: 16px;

  color: var(--primary);

  font-size: ac(21px, 16px);
  font-weight: 500;
}

.article-page__date {
  margin-right: ac(39px, 15px);
}

.article {
  padding: ac(108px, 40px) 0 ac(95px, 40px);
}

.article__content {
  max-width: 839px;

  margin-bottom: ac(100px, 40px);

  font-size: ac(18px, 16px);
  font-weight: 300;
  line-height: 1.2;

  @mixin min-media 1600 {
    max-width: 80%;
  }

  * {
    font-size: inherit;
    line-height: inherit;
  }

  p {
    font-weight: 300;
    line-height: 1.22;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  img {
    width: 100%;

    margin: 60px 0;

    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
}

.article__bottom-wrap {
  display: flex;
  flex-wrap: wrap-reverse;

  margin-bottom: -20px;
}

.article__back-btn {
  margin-right: 51px;
  margin-bottom: 20px;
}

.article__share-wrap {
  display: flex;
  align-items: center;

  margin-bottom: 20px;
}

.article__share-list {
  display: flex;
  align-items: center;
}

.article__share-title {
  margin-right: 13px;

  font-size: 14px;
  text-transform: uppercase;
}

.article__share-item {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.article__share-link {
  position: relative;
  background: linear-gradient(
    198deg,
    #6111d2 0%,
    #5e17d0 9%,
    #5729cc 23%,
    #4a48c6 38%,
    #3a71bd 56%,
    #24a7b1 74%,
    #0ae8a3 94%,
    #02fe9f 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 16px;
  line-height: 0;

  &:hover {
    -webkit-text-fill-color: var(--primary);
  }
}

.other-articles-section {
  padding-bottom: ac(129px, 40px);
}

.other-articles-swiper {
  overflow: hidden;

  .swiper-wrapper {
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }
}

.other-articles-swiper__navigation-wrap {
  display: flex;
  justify-content: center;
}

.other-articles-swiper__nav-btn {
  &:not(:last-child) {
    margin-right: 9px;
  }

  &.swiper-button-disabled {
    display: none;
  }
}

.progress-section {
  z-index: 1;

  padding: ac(113px, 40px) 0 ac(193px, 40px);
}

.progress__decor-top {
  z-index: -1;
  top: 230px;
  left: 12%;

  .decor-rect {
    width: 180px;
    height: 180px;

    transform: rotate(114deg);
  }

  @mixin media 769 {
    display: none;
  }
}

.progress__decor-bottom {
  z-index: -1;
  bottom: 50px;
  left: 12%;

  .decor-rect {
    width: ac(244px, 170px);
    height: ac(244px, 170px);

    transform: rotate(37.5deg);
  }

  @mixin media 769 {
    display: none;
  }
}

.progress__decor-middle-s {
  z-index: -1;
  top: 65%;
  right: 12%;

  .decor-rect {
    width: 80px;
    height: 80px;

    transform: rotate(60.7deg);
  }

  @mixin media 769 {
    display: none;
  }
}

.progress__decor-middle-m {
  z-index: -1;
  top: 58%;
  right: 0;

  .decor-rect {
    width: 180px;
    height: 180px;

    transform: translateX(55%) rotate(113deg);
  }

  @mixin media 769 {
    display: none;
  }
}

.progress-section__title {
  margin-bottom: 16px;

  font-size: ac(45px, 30px);
  text-align: center;
  line-height: 1;
}

.progress-section__descr {
  max-width: 554px;

  margin: 0 auto 69px;

  font-size: 18px;
  text-align: center;
  line-height: 1.23;
}

.progress-chain {
  position: relative;
  max-width: 964px;
  margin: 0 auto;
}

.progress-line {
  width: 4px;
  height: 100%;
  position: absolute;
  background: transparent;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  .fill {
    width: 100%;
    height: 10%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  @mixin media 769 {
    left: 0;
    transform: none;
  }
}

.progress-item {
  max-width: ac(381px, 290px);
  min-height: 250px;

  backdrop-filter: blur(10px);

  &:not(:last-of-type) {
    margin-bottom: -8%;
  }

  &:nth-child(odd) {
    margin-left: auto;
    transform: translateX(100%);
  }

  &:nth-child(even) {
    transform: translateX(-100%);
  }

  @mixin media 769 {
    max-width: 100%;

    min-height: unset;

    &:not(:last-of-type) {
      margin-bottom: 40px;
    }

    &:nth-child(odd),
    &:nth-child(even) {
      margin-left: ac(75px, 30px, 375, 769);
      text-align: left;
      transform: translateX(50%);
    }
  }
}

.progress__title {
  position: relative;

  margin-bottom: 26px;
  padding-left: 29px;

  font-size: ac(30px, 20px);
  line-height: 1.23;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 6px;
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  &::before {
    filter: blur(11px);
    transform: scale(1.1, 1.02);
  }
}

.progress__desc {
  font-size: ac(18px, 16px);
  font-weight: 300;
  line-height: 1.22;

  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

/* AOS */
[data-aos="progress-anim"] {
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.5s ease;

  &.aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}

.process-how-section {
  padding: ac(60px, 40px) 0 ac(152px, 40px);

  background-image: url(../images/process-how-section-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.portfolio-process {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ac(100px, 60px) 0;
    height: 100vh;
    min-height: 480px;

    .cont {
      position: relative;
      z-index: 2;
    }

    .process-how-section__title {
      font-size: ac(30px, 20px);
    }

    .process-how-section__subtitle {
      font-size: ac(16px, 14px);
    }

    .process-how-section__list {
      grid-template-columns: repeat(2, 1fr);

      max-width: 800px;

      margin: 0 auto;

      @mixin media 769 {
        grid-template-columns: 1fr;
      }
    }

    .process-how-section__list-item {
      text-align: center;
    }

    .process-how-section__item-title {
      font-size: ac(30px, 20px);
    }

    .process-how-section__item-descr {
      a {
        transition: color ease 250ms;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }
}

.process-how-section__title {
  max-width: 934px;

  margin: 0 auto ac(127px, 45px);

  font-size: ac(45px, 30px);
  text-transform: uppercase;
  line-height: 1.22;
  text-align: center;
}

.process-how-section__subtitle {
  font-size: 16px;
}

.process-how-section__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ac(79px, 35px) ac(49px, 35px);

  @mixin media 1025 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 769 {
    grid-template-columns: 1fr;
  }
}

.process-how-section__item-title {
  margin-bottom: 14px;

  font-size: 30px;
  font-weight: 500;
  line-height: 1.23;
  text-transform: uppercase;
}

.process-how-section__item-descr {
  font-size: 18px;
  line-height: 1.22;

  p {
    font-size: inherit;
    line-height: inherit;
  }
}

.careers-section {
  padding: ac(98px, 40px) 0 ac(80px, 40px);

  .cont {
    max-width: 1400px;
  }
}

.internal-careers__search-form-wrap {
  width: 83%;

  margin: 0 auto;

  @mixin media 1025 {
    width: 100%;
  }
}

.jobs-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ac(104px, 40px) ac(158px, 60px);

  margin-bottom: 80px;

  .results-not-found {
    grid-column: 1 / -1;
  }

  @mixin media 1281 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 769 {
    grid-template-columns: 1fr;
  }

  .results-not-found {
    font-size: 30px;
    text-align: center;
  }
}
.jobs-list__item {
  max-width: 621px;
}

.job-card {
  display: flex;
  flex-direction: column;

  height: 100%;

  &:hover {
    .job-card__title {
      color: var(--primary);
    }

    /* .job-card__info-wrap {
      color: var(--primary);
    }

    .job-card__descr {
      color: var(--primary);
    } */
  }
}

.job-card__title {
  position: relative;

  margin-bottom: 28px;
  padding-left: 29px;

  font-size: 30px;
  text-transform: uppercase;
  line-height: 1.23;

  transition: setTransition(color);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 6px;
    height: 100%;
    background-image: var(--main-gradient);
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
  }

  &::before {
    filter: blur(11px);
    transform: scale(1.1, 1.02);
  }

  @mixin media 360 {
    font-size: 25px;
    padding-left: 20px;
  }
}

.job-card__info-wrap {
  margin-bottom: 15px;

  font-size: 21px;
  font-weight: 500;
  line-height: 1.22;

  transition: setTransition(color);
}

.job-card__descr {
  @mixin max-line-length 3;

  margin-bottom: 15px;

  font-size: 18px;
  font-weight: 300;
  line-height: 1.23;

  transition: setTransition(color);

  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.job-card__btn {
  margin-top: auto;
}

.careers-section__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.careers-section__pagination-btn {
  width: 40px;
  height: 40px;

  font-size: 12px;

  &:not(:last-child) {
    margin-right: 9px;
  }
}

.intro-section {
  padding: ac(152px, 84px) 0 ac(221px, 147px);

  .cont {
    @mixin media 769 {
      width: 81.5%;
    }
  }
}

.intro-section__title {
  max-width: 916px;

  margin: 0 auto 3px;

  font-size: ac(45px, 30px);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @mixin media 769 {
    margin: 0 auto 18px;

    line-height: 1.2;
  }
}

.intro-section__descr {
  max-width: 554px;
  margin: 0 auto 46px;

  font-size: ac(18px, 16px);
  text-align: center;
  line-height: 1.3;

  @mixin media 769 {
    margin: 0 auto 60px;

    line-height: 1.4;
  }
}

.intro-swiper {
  margin-bottom: 45px;

  .swiper-slide {
    width: 364px;
    height: 141px;

    img {
      max-height: 100%;

      margin: 0 auto;
    }
  }

  @mixin media 769 {
    width: 100%;
    margin: 0 auto 59px;

    .swiper-slide {
      img {
        width: 100%;
      }
    }
  }
}

.intro-section__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  margin-right: -16px;
  margin-bottom: -16px;

  .btn {
    margin-right: 16px;
    margin-bottom: 16px;

    @mixin media 769 {
      width: 146px;

      margin-right: 10px;
      margin-bottom: 13px;

      font-size: 10px;
    }
  }

  @mixin media 769 {
    margin-right: -10px;
    margin-bottom: -13px;
  }
}

.statistics-section {
  margin-bottom: 154px;

  .cont {
    max-width: 1230px;
    width: perc(1230);
  }

  @mixin media 769 {
    margin-bottom: 90px;
  }
}

.statistic {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;

  margin-right: -58px;
  margin-bottom: -58px;
}

.statistic__block {
  max-width: 352px;

  margin-right: 58px;
  margin-bottom: 58px;

  text-align: center;

  @mixin media 769 {
    margin-bottom: 45px;
  }
}

.statistic__block-count {
  width: fit-content;

  margin: 0 auto;

  background: var(--btn-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: var(--font-main);
  font-size: 86px;
  font-weight: 600;
  line-height: 1.3;
}

.statistic__text {
  font-size: ac(30px, 26px);
  text-transform: uppercase;
  line-height: 1.4;

  @mixin media 769 {
    line-height: 1.05;
  }
}

.intro-section__left-decor {
  top: 28%;
  left: 11%;

  .decor-rect {
    width: 80px;
    height: 80px;

    transform: rotate(60.74deg);
  }

  @mixin media 1025 {
    display: none;
  }
}

.intro-section__right-decor {
  bottom: 0;
  right: -5%;
  z-index: -1;

  .decor-rect {
    width: vw(180);
    max-width: 180px;
    min-width: 112px;
    height: vw(180);
    max-height: 180px;
    min-height: 112px;

    transform: rotate(113.83deg);
  }
}

.btn {
  position: relative;
  z-index: 1;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px transparent;
  width: 174px;
  height: 34px;

  background-color: transparent;

  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;

  cursor: pointer;

  transition: letter-spacing 0.2s linear;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 0;

    border: 2px solid;
    border-image-source: var(--btn-gradient);
    border-image-slice: 1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;

    background-image: var(--btn-gradient);

    transition: setTransition(transform, opacity);
  }

  span {
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  }

  &:is(:hover, :focus) {
    letter-spacing: 1px;

    &::after {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;

    cursor: not-allowed;

    &:hover {
      &:after {
        opacity: 0;
      }
    }
  }
}

.btn.btn-invert {
  position: relative;
  z-index: 1;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px transparent;
  width: 174px;
  height: 34px;

  background-color: transparent;

  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;

  cursor: pointer;

  transition: letter-spacing 0.2s linear;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 0;

    border: 2px solid;
    border-image-source: linear-gradient(
      180deg,
      #6111d2 0%,
      #5e17d0 9%,
      #5729cc 23%,
      #4a48c6 38%,
      #3a71bd 56%,
      #24a7b1 74%,
      #0ae8a3 94%,
      #02fe9f 100%
    );
    border-image-slice: 1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;

    background-image: linear-gradient(
      180deg,
      #6111d2 0%,
      #5e17d0 9%,
      #5729cc 23%,
      #4a48c6 38%,
      #3a71bd 56%,
      #24a7b1 74%,
      #0ae8a3 94%,
      #02fe9f 100%
    );

    transition: setTransition(transform, opacity);
  }

  span {
    z-index: 1;
  }

  &:is(:hover, :focus) {
    letter-spacing: 1px;

    &::after {
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
    }
  }
}

.btn.btn--icon {
  font-size: 12px;
  height: 35px;
  width: 35px;
}
